<template>
    <div class="widget-container">
        <div class="title">
            Динаміка продажів
        </div>
        <div class="btn-container">
            <button @click="sortBy='fundraising'; getMovieList('fundraising')" :class="{active : sortBy==='fundraising'}" class="btn fundraising">збори</button>
            <button @click="sortBy='spectators'; getMovieList('spectators')" :class="{active : sortBy==='spectators'}" class="btn">глядачі</button>
            <button @click="sortBy='sessions'; getMovieList('sessions')" :class="{active : sortBy==='sessions'}" class="btn">сеанси</button>
        </div>
        <div class="select-container">
            <SelectComponent
                class="select-item"
                :options="selectOptions"
                @input="changeSelect"
                :value="tst"
            />
<!--            <select class="selectpicker select-item">-->
<!--                <option value="year">Рік</option>-->
<!--                <option value="month">Місяць</option>-->
<!--                <option value="day">День</option>-->
<!--            </select>-->

            <!--            <DatePickerComponent-->
<!--                class="date-picker"-->
<!--                name="date"-->
<!--                @input="changeDate"-->
<!--            />-->
            <div class="date">
                <input type="text" class="datepicker date-picker"/>
                <span class="material-icons-outlined date-picker-icon">event</span>
            </div>
        </div>
        <div class="graph">
            <div id="chartReport">
                <canvas id="myChart" width="400" height="200"></canvas>
            </div>
        </div>
        <img class="image-empty d-none" src="@/assets/img/folder-empty.svg" alt="svg">
        <div class="main-list"></div>
    </div>
</template>

<script>
import SelectComponent from "../ui/forms/SelectComponent";
import DatePickerComponent from "../ui/forms/DatePickerComponent";
import ChartsContainer from "@/components/ui/charts/ChartsContainer";



export default {
    name: "Widget",
    components: {
        SelectComponent,
        DatePickerComponent,
        ChartsContainer,
    },
    data() {
        return {
            sortBy: 'fundraising',
            tst: '',
            selectOptions: [
                {id: 'year', label: 'Рік'},
                {id: 'month', label: 'Місяць'},
                {id: 'day', label: 'День'},
            ],
            format: 'year',
            target: 'fundraising',
            dateFrom: '2019-10-10',
            dateTo: '2021-10-21'
        }
    },
    methods: {
        changeSelect(value) {
            this.format = value
            this.getMovieList(this.target)
        },
        changeDate(value) {
            this.dateFrom = value
            this.getMovieList(this.target)
        },
        async getMovieList(target) {
            let dateFrom = this.dateFrom
            let dateTo = this.helper.getDate(new Date)
            let movies = []

            const response = await fetch('/api/v1/widget/top-movies?' + new URLSearchParams(
                {date_to: dateTo, date_from: dateFrom, format: this.format}
            ))
            const results = await response.json();
            movies = results.movies
            let analytic = results.analytic
            this.renderList(movies, target)
            this.updateGraph(analytic)
        },
        updateGraph(analytic){
            let countSum = []
            let sessionSum = []
            let amountCount = []

            if (analytic){
                document.querySelector('.image-empty').classList.add('d-none')
                document.querySelector('.graph').classList.remove('d-none')
                analytic.forEach(item => {
                    countSum.push({
                        date: item.date,
                        count: item.count_sum,
                    })
                    sessionSum.push({
                        date: item.date,
                        count: item.sessions_count,
                    })
                    amountCount.push({
                        date: item.date,
                        count: item.amount_sum,
                    })
                })
            } else {
                document.querySelector('.image-empty').classList.remove('d-none')
                document.querySelector('.graph').classList.add('d-none')

            }
            function sortSum(array){
                let result = []
                array.forEach(item => {
                    let element = result.find(el => el.date === item.date)
                    if (element){
                        element.count = +element.count + +item.count
                    } else {
                        result.push(item)
                    }
                })
                return result
            }

            function getKeyGraph(arr){
                let key = []
                sortSum(arr).forEach(item => {
                    key.push(item.date)
                })
                return key.reverse()
            }

            function getValueGraph(arr){
                let value = []
                sortSum(arr).forEach(item => {
                    value.push(item.count)
                })
                return value
            }


            let sortByKey;
            let sortByValue;

            if (this.target === 'fundraising'){
                sortByKey = getKeyGraph(amountCount)
                sortByValue = getValueGraph(amountCount)

            } else if (this.target === 'spectators'){
                sortByKey = getKeyGraph(countSum)
                sortByValue = getValueGraph(countSum)

            } else if (this.target === 'sessions'){
                sortByKey = getKeyGraph(sessionSum)
                sortByValue = getValueGraph(sessionSum)
            }

            document.querySelector("#chartReport").innerHTML = '<canvas id="myChart"></canvas>';
            let ctx = document.getElementById('myChart').getContext('2d');
            let myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: sortByKey,
                    datasets: [{
                        data: sortByValue,
                        backgroundColor: [
                            "#ffeaa2",
                            "#8597ff",
                            "#ff8298",
                            "#a0d0ff",
                            "#c0ff94",
                            "#3896bc",
                            "#fcbfa0",
                            "#9497aa",
                            "#76f3b8",
                            '#00e0ff',
                        ],
                        borderColor: [
                            '#00e0ff',
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: 'rgb(255, 99, 132)'
                            }
                        }
                    }
                }
            });



        },
        renderList(movies, target) {
            document.querySelector('.main-list').innerHTML = '';
            let count = 0

            this.target = target
            if (movies){
                movies.forEach(movie => {
                    let sort = movie.stats.tickets_count
                    if (target === 'fundraising'){
                        sort = movie.stats.tickets_amount
                    } else if (target === 'spectators'){
                        sort = movie.stats.tickets_count
                    } else if (target === 'sessions'){
                        sort = movie.stats.sessions_count
                    }
                    ++count
                    document.querySelector('.main-list')
                        .insertAdjacentHTML(
                            'beforeend',
                            `<div class="list-item">
                                    <div class="left-block">
                                        <span class="item-id">${count}</span>
                                        <span class="item-name">${movie.name}</span>
                                    </div>
                                    <span class="item-number">${sort}</span>
                               </div>`
                        )
                })
            }
        },
        createDatePiker(){
            setTimeout(() => {
                let t = this;

                $.fn.datepicker.dates['ua'] = {
                    days: ["Неділя", "Понеділок", "Вівторок", "Середа", "Четвер", "Пятниця", "Субота"],
                    daysShort: ["Нед", "Пон", "Вів", "Сер", "Чет", "П'ят", "Суб"],
                    daysMin: ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                    months: ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"],
                    monthsShort: ["Січ", "Лют", "Берез", "Квіт", "Трав", "Черв", "Лип", "Серп", "Верес", "Жовт", "Листоп", "Груд"],
                    today: "Сьогодні",
                    clear: "Очистити",
                    format: "mm/dd/yyyy",
                    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
                    weekStart: 1
                };



                $('.datepicker').datepicker({language: 'ua'}).on('changeDate', function(e) {
                    t.changeDate($(this).val());
                });


                $('.selectpicker').selectpicker().on('change', function (e) {
                    t.changeSelect($(this).val())
                })

                this.dateTo = moment(new Date).format('YYYY-MM-DD')


            }, 1000)

            setInterval(() => {
                this.dateTo = moment(new Date).format('YYYY-MM-DD')
                this.getMovieList(this.target)
            }, 300000)
        }
    },
    beforeMount() {
        let scriptJquery = document.createElement('script')
        scriptJquery.setAttribute('src', 'https://code.jquery.com/jquery-3.6.0.min.js')
        scriptJquery.setAttribute('integrity', 'sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=')
        scriptJquery.setAttribute('crossorigin', 'anonymous')
        document.head.appendChild(scriptJquery)

        let script = document.createElement('script')
        script.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/3.5.1/chart.min.js')
        document.head.appendChild(script)

        // let bootstrapMinJs = document.createElement('script')
        // bootstrapMinJs.setAttribute('src', 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js')
        // document.head.appendChild(bootstrapMinJs)

        let styleDatepicker = document.createElement('link')
        styleDatepicker.setAttribute('href', 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/css/bootstrap-datepicker.standalone.min.css')
        styleDatepicker.setAttribute('rel', 'stylesheet')
        document.head.appendChild(styleDatepicker)

        let styleSelect = document.createElement('link')
        styleSelect.setAttribute('href', 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.13.18/css/bootstrap-select.min.css')
        styleSelect.setAttribute('rel', 'stylesheet')
        document.head.appendChild(styleSelect)

        let bootstrapSelect = document.createElement('script')
        bootstrapSelect.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.13.18/js/bootstrap-select.min.js')
        document.head.appendChild(bootstrapSelect)

        let scriptDatepicker = document.createElement('script')
        scriptDatepicker.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/js/bootstrap-datepicker.min.js')
        document.head.appendChild(scriptDatepicker)

        let momentJs = document.createElement('script')
        momentJs.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.1/moment.min.js')
        document.head.appendChild(momentJs)
    },
    mounted() {
        this.getMovieList(this.target)
        this.createDatePiker()
    }
}
</script>

<style  lang="scss">
.widget-container {
    max-width: 370px;
    background-color: white;
    padding: 15px;
    min-width: 270px;

    .title {
        font-size: 18px;
        font-weight: 600;
        color: #141213;
        border-bottom: 1px solid #cad9f3;
        padding-bottom: 21px;
        margin-top: 5px;
    }

    .btn-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 14px 0;

        .btn {
            width: 33.3%;
            height: 31px;
            border-radius: 1px;
            border: solid 1px #cad9f3;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            letter-spacing: 0.35px;
            color: black;
            background-color: white;
            cursor: pointer;

            &:focus {
                box-shadow: none;
            }
        }



        .active {
            font-size: 14px;
            letter-spacing: 0.35px;
            text-align: center;
            color: white;
            background-color: $color_lightpink;
        }
    }



    .select-container {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .bootstrap-select{
            width: 100%!important;
            height: 45px;
            margin-right: 5px;
        }
        .dropdown-toggle{
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            letter-spacing: 0.35px;
            cursor: pointer;
            background-color: #F8FAFF;
            border: solid 1px #EEF3FD;
            border-radius: 2px;
            &:focus-visible, &:focus{
                outline: none!important;
            }
            .filter-option-inner{
                font-size: 16px;
                color: #141213;
                padding-left: 2px;
                padding-top: 5px;
            }
        }
        .date{
            position: relative;
            width: 95%;
        }
        .date-picker-icon{
            position: absolute;
            color: $color_darkblue;
            right: 10px;
            top: 12px;
            font-size: 18px;
        }
        .select-item, .date-picker {
            width: 48%;
        }
        .date-picker{
            background-color: #F8FAFF;
            cursor: pointer;
            border: solid 1px #EEF3FD;
            border-radius: 2px;
            height: 45px;
            width: 100%;
            font-size: 16px;
            color: #141213;

            &:focus-visible{
                outline: none;
            }
        }
    }

    .image-empty{
        width: 55%;
        display: block;
        margin: 15px auto;
    }
    .main-list {
        margin-top: 15px;

        .list-item {
            min-height: 47px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #edf3ff;

            .item-id, .item-name {
                font-size: 16px;
                color: #646e78;
            }
            .item-name{
                position: static;
                left: 45px;
            }
            .item-id {
                margin-right: 20px;
            }

            .item-number {
                font-size: 16px;
                font-weight: 900;
                color: #141213;
            }
        }

    }

    .d-none{
        display: none;
    }

    .select-element{
        width: 100%!important;
    }


}
.table-condensed .active{
    background-color: white!important;
    background-image: none!important;
    color: #2a90e9!important;
}
.datepicker{
    background-color: #fff;
    border: 1px solid #e8e8e8;
}

</style>
